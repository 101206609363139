/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { MaybeRef } from 'vue'
import { computed, toValue } from 'vue'
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useEngagementNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { getLocalePath, navigateTo, route, uuidEncoder } = useNavigation()

  const params = computed<Record<string, string>>(() => ({
    organisationId: uuidEncoder.encode(toValue(organisation)['@id'].toId()),
    organisationSlug: toValue(organisation).getSlug().toString(),
    engagementId: uuidEncoder.encode(toValue(engagement)['@id'].toId()),
    engagementSlug: toValue(engagement).getSlug().toString(),
    phaseId: uuidEncoder.encode(toValue(phase)['@id'].toId()),
    phaseSlug: toValue(phase).type.toString(),
  }))

  const getEngagementLocalePath = (
    route: Route,
  ) => {
    if (typeof route === 'string') {
      return getLocalePath({
        name: route,
        params: params.value,
      })
    }

    route.params = {
      ...route.params,
      ...params.value,
    }

    return getLocalePath(route)
  }

  const engagementDashboardLocalePath = computed(() =>
    getEngagementLocalePath('dashboard-Index'),
  )

  const navigateToEngagementLocalePath = async (route: Route) => {
    await navigateTo(getEngagementLocalePath(route))
  }

  return {
    route,
    getLocalePath,
    getEngagementLocalePath,
    navigateTo,
    navigateToEngagementLocalePath,
    engagementDashboardLocalePath,
    uuidEncoder,
  }
}
